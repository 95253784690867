import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { SelectOption } from "../MainBody/Form";
import { LabelForAuto } from "./StyledComponents";
import { forwardRef, useEffect, useRef } from "react";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const BootstrapInputForSelectField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: "5px",
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#E1E2EB",
      border:
        theme.palette.mode === "dark"
          ? "1px solid #4B5563"
          : "1px solid #D1D5DB",
    },
    fontFamily: "San Francisco Text",
    "&.Mui-focused fieldset": {
      boxShadow: `rgb(25 118 210 / 25%) 0 0 0 0.2rem`,
      border: `1.5px solid #1976d2`,
    },
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "#e5e7e9",
  },
}));

interface Option {
  id: string;
  label: string;
  fuel?: string;
}

const AutoCompleteInput = forwardRef(
  (
    props: {
      files?: any;
      label: string;
      listOption: SelectOption[];
      onChange?: Function;
      value: SelectOption | undefined;
      disabled?: boolean;
      placeholder?: string;
      open?: boolean;
      onClose?: Function;
      onOpen?: Function;
      setFiles?: Function;
      setDataFromImage?: Function;
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const theme = useTheme();

    useEffect(() => {
      if (props.open && inputRef.current) {
        inputRef.current.focus();
      }
    }, [props.open]);

    const getOptionLabel = (option: Option) => {
      if (option.id !== "" && option.fuel) {
        return option.label + ` (${option.fuel})`;
      }
      if (option.label === "LRTN" && parseInt(option.id) === 2) {
        return "Lắp ráp trong nước";
      }
      if (option.label === "NK" && parseInt(option.id) === 1) {
        return "Nhập khẩu";
      }
      return option.label;
    };

    return (
      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
        <LabelForAuto
          style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}
        >
          {props.label}
        </LabelForAuto>
        <Tooltip title={props.disabled ? "" : props.value?.label} followCursor>
          <Autocomplete
            value={props.value}
            disablePortal
            options={props.listOption}
            getOptionLabel={getOptionLabel}
            sx={{ width: `100%` }}
            onChange={(_, newValue) => {
              _.stopPropagation();
              if (props.onChange) {
                const currentValue = newValue ? newValue : { id: "", label: "" }; 
                props.onChange(currentValue);
                if (props.files) {
                  props.setFiles && props.setFiles([]);
                }
              }
            }}
            disabled={props.disabled}
            renderInput={(params) => (
              <BootstrapInputForSelectField
                {...params}
                placeholder={props.placeholder}
                size="small"
                inputRef={(el) => {
                  inputRef.current = el;
                  if (ref) {
                    if (typeof ref === "function") {
                      ref(el);
                    } else {
                      ref.current = el;
                    }
                  }
                }}
              />
            )}
            open={props.open}
            onClose={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
            onOpen={() => {
              if (props.onOpen) {
                props.onOpen();
              }
            }}
          />
        </Tooltip>
      </FormControl>
    );
  }
);

export default AutoCompleteInput;
